import { px } from '@/layout/GlobalStyles';
import greenDots from '@a/green-dots.svg';
import styled from '@emotion/styled';

const StyledArticle = styled.article<{ dots?: boolean }>`
    ${px}
    padding-top: 32px;
    padding-bottom: 32px;
    max-width: 1400px;
    margin: 0 auto;

    a:not(.btn) {
        color: ${({ theme }) => theme.green};
        transition: color 0.3s ease-out;

        :hover,
        :focus {
            color: ${({ theme }) => theme.darkGreen};
        }
    }

    > .service-article-intro {
        > .gatsby-image-wrapper,
        > p:first-of-type {
            max-width: 500px;
            margin: 0;
            border-radius: 24px;
            opacity: 0.9999; //safari overflow bug fix
        }

        > div:last-of-type {
            max-width: 550px;

            h2 {
                margin: 32px 0;
                text-transform: capitalize;
            }

            p {
                margin: 0;
            }
        }
    }

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;

        > .service-article-intro {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 64px;

            > .gatsby-image-wrapper,
            > p:first-of-type {
                max-width: 647px;
                width: 52%;
                order: 1;
            }

            > div:last-of-type {
                width: calc(48% - 64px);
            }
        }
    }

    @media (min-width: 1440px) {
        position: relative;

        ::before,
        ::after {
            content: '';
            position: absolute;
            width: 116px;
            height: 116px;
            background: url(${greenDots}) no-repeat;
            z-index: -1;
            display: ${({ dots }) => (dots ? 'block' : 'none')};
        }

        ::before {
            /* article max width 1400px divided by space on the right side, -1 to get out of article then add scrollbar width */
            right: calc((((100vw - 1400px) / 2) * -1) + 10px);
            top: 1050px;
        }

        ::after {
            left: calc((((100vw - 1400px) / 2) * -1) - 16px);
            top: 1890px;
        }
    }

    @media (min-width: 1600px) {
        p,
        li,
        a {
            font-size: 1.125rem;
        }
    }
`;

type ArticlePProps = {
    children: React.ReactNode;
    dots?: boolean;
};

export const Article = ({ children, dots }: ArticlePProps) => {
    return (
        <StyledArticle id="main-article" dots={dots}>
            {children}
        </StyledArticle>
    );
};
