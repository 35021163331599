import { PrimarySmBtn } from '@/Buttons/PrimarySmBtn';
import styled from '@emotion/styled';

const Call = styled.div`
    padding: 32px 16px;
    position: relative;
    color: #fff;
    background: ${({ theme }) => theme.white};
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 32px;
    margin-top: 64px;
    text-align: center;

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        margin: -1px;
        border-radius: inherit;
        background: ${({ theme }) => theme.gradient};
    }

    > h5 {
        font-size: 2.25rem;
        line-height: 162%;
        font-family: 'Raleway', sans-serif;
        color: ${({ theme }) => theme.green};
        margin: 0 0 32px;
        text-align: left;
    }

    > p {
        color: ${({ theme }) => theme.darkGreen};
        margin: 0 0 32px;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 162%;
        text-align: left;
    }

    > a {
        font-family: 'Roboto', sans-serif;
    }

    @media (min-width: 768px) {
        > h5,
        p {
            text-align: center;
        }
    }
`;

export const CallUs = () => {
    return (
        <Call>
            <h5>Be one step ahead, call us:</h5>
            <p>For more information, book an appointment with our specialists today.</p>
            <PrimarySmBtn as="a" href="tel:+19523001461" gradient className="btn">
                (952) 300 - 1461
            </PrimarySmBtn>
        </Call>
    );
};
