import { Article } from '@/Article';
import { CallUs } from '@/CallUs';
import { Faq } from '@/Faq';
import { h2, h3 } from '@/layout/GlobalStyles';
import { Seo } from '@/layout/Seo';
import { ServiceHero } from '@/ServiceHero';
import { SingleQuestion } from '@/SingleQuestion';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Fragment } from 'react';

const H2 = styled.h2`
    ${h2};
    margin: 32px 0 16px;
    text-transform: capitalize;
`;

const H3 = styled.h3`
    ${h3};
    margin: 0;

    & + p {
        margin-top: 8px;
    }
`;

const Images = styled.div<{ width?: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0;
    gap: 32px;
    flex-wrap: wrap;

    > p {
        margin: 0;
        width: ${({ width }) => `min(100%, ${width}px)`};
    }

    .gatsby-resp-image-wrapper {
        aspect-ratio: unset !important;
    }

    @media (min-width: 1280px) {
        margin: 64px 0;
    }
`;

type ServiceProps = {
    data: {
        mdx: {
            body: string & React.ReactNode;
            frontmatter: {
                seoTitle: string;
                seoDescription: string;
                cover: {
                    childImageSharp: {
                        resize: {
                            src: string;
                        };
                    };
                };
            };
        };
    };
    pageContext: {
        slug: string;
    };
};

const shortcodes = { Faq, SingleQuestion, h2: H2, h3: H3, Images };

export default function Service({ pageContext, data }: ServiceProps) {
    const {
        mdx: {
            frontmatter: { seoTitle, seoDescription, cover },
            body,
        },
    } = data;

    return (
        <Fragment>
            <Seo
                title={seoTitle}
                description={seoDescription}
                image={cover.childImageSharp.resize.src}
                url={pageContext.slug.substring(1)}
            />
            <ServiceHero />
            <MDXProvider components={shortcodes}>
                <Article dots>
                    <MDXRenderer>{body}</MDXRenderer>
                    <CallUs />
                </Article>
            </MDXProvider>
        </Fragment>
    );
}

export const query = graphql`
    query ($slug: String!) {
        mdx(fileAbsolutePath: { regex: $slug }) {
            frontmatter {
                seoTitle
                seoDescription
                cover {
                    childImageSharp {
                        resize(fit: COVER, width: 630, height: 630) {
                            src
                        }
                    }
                }
            }
            body
        }
    }
`;
